.hero-container-main-wrap {
  // height: 90vh;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.service-inner-area-wrap {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer-single-item-content > a > img {
  height: 55px;
}
.hero-item-left-content > p {
  /* width: 80%; */
  margin: 25px 50px;
}
.hero-footer-image-wrap-fieldx > .row {
  justify-content: center;
  height: 100%;
}

.hero-footer-image-wrap-fieldx {
  position: absolute;
}

.hero-footer-image-wrap-fieldx {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 100%;
}
.fieldx-hero-inner-wrap > .container {
  height: 100%;
}
.fieldx-hero-inner-wrap > .container > * {
  height: 100%;
}
.fieldx-hero-inner-wrap > .container > * > .hero-footer-image-wrap-fieldx {
  height: 100%;
}
.fieldx-hero-inner-wrap > .container > * > * > * > .my-auto.col-lg-8 {
  height: 100%;
  position: relative;
}
.fieldx-hero-inner-wrap > .container > * > * > * > * {
  height: 100%;
}
.fieldx-hero-inner-wrap > .container > * > * > * > * > * > img {
  height: auto;
  display: flex;
  position: absolute;
  bottom: -460px;
  z-index: 999;
  width: 100%;
}
.hero-footer-image-wrap-fieldx- > * {
  justify-content: center;
  margin-top: 30px;
}
.hero-top-content-wrap-fieldx {
  padding-top: 50px;
  margin-bottom: 40px;
}
.service-single-item > img {
  margin-bottom: 8px;
}

li.navbar-salesx-right-single-btn {
  border-radius: 10px;
  background: #fd7e14;
}

li.navbar-salesx-right-single-btn {
  a {
    color: #fd7e14 !important;
  }
}
button.btn.feature-left-btn.hero.btn--swap {
  span {
    width: unset;
    height: unset;
    font-size: unset;

    background: unset;
    display: block;

    color: unset;
    margin-bottom: unset;
    // border-radius: 10px;
  }
  &:hover a {
    color: #fff !important;
  }
}
button.btn.feature-left-btn.hero {
  background: #0180f5;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  padding: 8px 20px;
  padding-bottom: 10px;
}

.hero-brand-icon-right {
  width: 60%;
  position: absolute;
  right: 0;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  z-index: 9;
  top: 0;
}
.hero-brand-icon-right img {
  width: 100%;
}
.hero-inner-wrap {
  position: relative;
}
.hero-item-right-content img {
  width: 100%;
  margin: 0 auto !important;
  display: block;
}
.image-feature-mobile img {
  height: 100%;
}
.feature-main-area {
  position: relative;
  z-index: 9;
  padding-top: 50px;
  /* margin-top: 350px; */
}
.feature-inner-single-item span {
  width: 50px;
  height: 50px;
  background: #fd7e141a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  color: #0180f5;
  margin-bottom: 20px;
  border-radius: 10px;
}
.feature-inner-single-item.palning h4 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}
.feature-inner-single-item.palning p {
  font-size: 18px;
  margin-bottom: 25px;
}
button.btn.feature-left-btn.palning {
  border: 2px solid #fd7e14;
  border-radius: 10px;
  font-weight: 600;
  color: #fd7e14;
}

button.btn.feature-left-btn a {
  color: #fd7e14;
  text-decoration: none;
}

.feature-inner-single-item.LiveShare h4 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.feature-inner-single-item.LiveShare p {
  margin-bottom: 25px;
}
.palning-main-area {
  padding-top: 50px;
}

.industries-single-item {
  width: 100%;
  height: 250px;
  background: #ddd;
}

.industries-main-wrap .slick-slide {
  padding: 10px;

  text-align: center;
}

.industries-single-item {
  width: 100%;
  height: 150px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 20px 0px #dddddd78;
  border-radius: 10px;
}
.industries-single-item > * > * > img {
  margin: auto;
  display: inline-block;
  margin-bottom: 15px;
}
.industries-single-item-inner {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.industries-single-item-inner > * > * > * {
  text-align: center;
  margin: auto;
  margin-bottom: 12px;
}
.footer-single-item-content > ul {
  padding-left: 0;
}

.footer-single-item-content ul li {
  list-style: none;
}
.review-area-hero span {
  color: #ffd001;
}

.review-area-hero {
  margin-top: 15px;
  margin-bottom: 40px;
}

.review-area-hero > span > span {
  color: #000;
}

.review-area-hero span {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

/* =========================================  */
.hero-top-content-wrap-fieldx > .row {
  justify-content: center;
  text-align: center;
}

.fieldx-hero-inner-wrap {
  position: relative;
  /*  */
  width: 100%;
  margin-top: 30px;
  border-radius: 25px;
}

.hero-top-content-wrap-fieldx {
  padding-top: 50px;
  margin-bottom: 40px;
  position: relative;
  z-index: 9;
}
.hero-footer-image-wrap-fieldx- {
  position: relative;
  z-index: 9;
  img {
    position: relative;
    z-index: 9;
  }
}
.hero-inner-wrap {
  width: 100%;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background: #fffbf8;
    top: 0;
    left: 0;
    width: 100%;
    height: 77%;

    // opacity: 0.5;
    border-radius: 40px;
    z-index: 1;
  }
}
.feature-inner-single-item.image-feature-mobile.image-bouns-animation.feature-middle-app-pic
  img {
  width: 100%;
  height: auto;
}
.feature-inner-single-item {
  margin-bottom: 50px;
}
.service-inner-wrap {
  padding-top: 80px;
}

.service-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
  justify-content: center;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  padding: 50px 20px;
  border-radius: 10px;
}
.service-single-data {
  text-align: center;
}
.service-main-area {
  padding: 110px 0;
}
.service-area-top-content {
  text-align: center;
}
.service-area-top-content h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}
.service-area-top-content p {
  font-size: 18px;
  margin-bottom: 25px;
}
.service-single-data h1 {
  font-weight: 700;
}
/* =========================================  */
@media only screen and (min-width: 1600px) {
  .fieldx-hero-inner-wrap > .container > * > * > * > * > * > img {
    height: auto;
    display: flex;
    position: absolute;
    bottom: -300px;
    z-index: 999;
    width: 100%;
  }
}
@media only screen and (min-width: 1499px) and (max-width: 1600px) {
  .service-inner-wrap {
    padding-top: 460px !important;
  }
}
@media only screen and (max-width: 1499px) {
  .fieldx-hero-inner-wrap > .container > * > * > * > * > * > img {
    height: auto;
    display: flex;
    position: absolute;
    bottom: -300px;
    z-index: 999;
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .fieldx-hero-inner-wrap > .container > * > * > * > * > * > img {
    height: auto;
    display: flex;
    position: absolute;
    bottom: -460px;
    z-index: 999;
    width: 100%;
  }
  .hero-container-main-wrap {
    min-height: 80vh;
  }
  .hero-item-right-content img {
    width: 100%;
    height: auto;
  }

  .image-feature-mobile img {
    width: 100%;
    height: auto;
  }

  .call-to-action-left {
    width: 40%;
  }
  .call-to-action-input-wrap button {
    width: 45%;
  }
  .call-to-action-input-wrap input {
    width: 53%;
  }
}
.footer-single-item-content > p {
  color: #fff;
}
@media only screen and (max-width: 992px) {
  .hero-inner-wrap > .row {
    flex-direction: column-reverse;
    text-align: center;
  }
  .hero-item-left-content > p {
    width: 100%;
    margin: 15px 0;
  }
  .feature-inner-area-wrap > .row {
    flex-direction: column-reverse;
  }
  .image-feature-mobile {
    text-align: center;
    margin-bottom: 30px;
  }
  .image-feature-mobile img {
    width: unset;
    height: 450px;
    margin: auto;
  }
  .palning-inner-area-wrap.planing-page > .row {
    text-align: center;
    flex-direction: column-reverse;
  }
  .feature-inner-area-wrap {
    text-align: center;
  }
  .feature-inner-single-item span {
    margin: 20px auto;
  }
  .call-to-action-area {
    flex-wrap: wrap;
  }
  .call-to-action-input-wrap {
    flex-wrap: wrap;
  }
  .call-to-action-input-wrap input {
    width: 100%;
  }
  .call-to-action-input-wrap button {
    width: 100%;
  }
  .call-to-action-left h2 {
    font-size: 20px;
  }
  .feature-inner-single-item.LiveShare {
    text-align: center;
  }
  .hero-brand-icon-right {
    display: none;
  }
  .fieldx-hero-inner-wrap > .container > * > * > * > * > * > img {
    height: auto;
    display: flex;
    position: absolute;
    bottom: -130px;
    z-index: 999;
    width: 100%;
  }
  .col-lg-4.col-md-6.col-6.footer-bottom-item-hide-area {
    display: none;
  }
  .footer-single-item-content > * > img {
    color: #fff;
    height: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .call-to-action-left {
    width: 100%;
  }
  .call-to-action-right {
    width: 70%;
    margin: auto;
  }
  .image-feature-mobile img {
    width: unset;
    height: 300px;
    margin: auto;
  }
  .hero-container-main-wrap {
    height: 100vh;
  }
  .industries-single-item {
    height: 140px;
  }
  .industries-single-item-inner > * > * > img {
    height: 50px;
  }
  .industries-single-item-inner > * > h5 {
    font-size: 16px;
  }
  .palning-main-area.live-share-area {
    padding-bottom: 40px;
  }
  .header-logo a {
    display: inline-block;
    height: 40px !important;
  }
  .review-area-hero span {
    justify-content: center;
  }
  .service-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(29%, 1fr));
    justify-content: center;
    width: 100%;
    text-align: center;
    background: #f8f8f8;
    padding: 50px 20px;
    border-radius: 10px;
    gap: 4vw;
  }
}
@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 992px) {
}
// ============================================================================
.palning-inner-area-wrap.live-share-inner {
  background: #fffbf8;
  padding: 50px 40px;
  border-radius: 40px;
  padding-top: 70px;
  padding-bottom: 0px;
}
