.image-bouns-animation img {
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.image-bouns-animation img {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

/* #animated-example {
  width: 20px;
  height: 20px;
  background-color: red;
  position: relative;
  top: 100px;
  left: 100px;
  border-radius: 50%;
} */

@-webkit-keyframes bounce {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
  }
}

@keyframes bounce {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.btn--swap {
  overflow: hidden;
}
.btn--swap div,
.btn--swap span {
  position: relative;
}

.btn--swap span {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  display: block;
  transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
}

.btn--swap span:nth-of-type(2) {
  color: #000000;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, 250%);
}
.btn--swap:hover span:nth-of-type(2) {
  --text-opacity: 1;
  /* color: #fff; */

  transform: translate(-50%);
}
.btn--swap:hover .hero-button-area-left-2 {
  --text-opacity: 1;
  color: #fff;

  transform: translate(-50%);
}
.btn--swap:hover span:first-of-type {
  transform: translateY(-250%);
}
button.btn.feature-left-btn.hero:hover {
  background: #fd7e14 !important;
}
span.playstore {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #000;
}
.hero-main-area-button {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99;
}
button.btn.feature-left-btn.hero.btn--swap.play-store-button {
  background: #fff;
  border: 2px solid #000;
  color: #fd7e14;
}
button.btn.feature-left-btn.hero.btn--swap.play-store-button:hover {
  border: 2px solid #000;
  color: #000 !important;
}
button.btn.feature-left-btn.hero.btn--swap a {
  color: #fff;
}
.hero-button-area-left-1 a {
  color: #fd7e14 !important;
}
button.btn.feature-left-btn.hero {
  background: #ffffff !important;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  padding: 8px 20px;
  padding-bottom: 10px;
  border: 2px solid #fd7e14;
}
