/* ============================ */
body {
  font-family: "DM Sans", sans-serif;
}
/* ============================ */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header.header-main-area {
  position: sticky;
  top: 0;
  z-index: 99999;
  background: #fff !important;
  box-shadow: 0px 0px 10px 0px #dddddd94;
}
.hero-item-left-content h3 {
  font-size: 48px;
  font-weight: 700;
}

/* .service-inner-wrap {
  padding-top: 500px;
} */
.section-title {
  text-align: center;
  margin-bottom: 50px;
}
main {
  overflow: hidden;
}
