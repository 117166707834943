.our-process-wrap {
  display: flex;
  align-items: center;
  gap: 4vw;
  padding: 50px 0;
}

.our-process-left {
  width: 60%;
}

.our-process-left h6 {
  letter-spacing: 3px;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.our-process-left h2 {
  margin-bottom: 15px;
  font-size: 50px;
}

.our-process-left ul {
  padding-left: 00px;
}

.our-process-left ul li {
  line-height: 26px;
  font-size: 14px;
  color: #232536;
}
.our-process-left ul li {
  line-height: 26px;
  font-size: 14px;
  color: #232536;
  list-style: none;
}
.our-process-right {
  width: 35%;
}

.our-process-right img {
  width: 100%;
}

.call-to-action-area {
  background: #1b3f73;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6% 5%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.call-to-action-left h2 {
  color: #fff;
  font-weight: 600;
  font-size: 40px;
}

.call-to-action-main-area {
  padding: 50px 0;
}

.call-hero-button-area a {
  margin-right: 15px;
}
.call-hero-button-area a:last-child {
  margin-right: 0px;
}

.call-to-action-left {
  width: 50%;
}
.call-to-action-right {
  width: 50%;
}
.call-to-action-input-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.call-to-action-input-wrap input {
  border-radius: 10px;
  padding: 10px 15px;
  width: 65%;
  border: none;
}
.call-to-action-input-wrap button {
  border-radius: 10px;
  padding: 10px 15px;
  width: 33%;
  border: none;
  font-weight: 600;
}
@media only screen and (max-width: 992px) {
  .call-hero-button-area a {
    margin-right: 10px;
  }
  .our-process-wrap {
    display: block;
  }
  .our-process-left {
    width: 100%;
    margin-bottom: 50px;
  }
  .our-process-right {
    width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .call-hero-button-area a {
    margin-right: 10px;
  }
  .our-process-wrap {
    display: block;
  }
  .our-process-left {
    width: 100%;
    margin-bottom: 50px;
  }
  .our-process-right {
    width: 100%;
  }
  .hero-inner-left {
    width: 100%;
  }
  .hero-inner-right {
    width: 100%;
  }
  .call-to-action-area {
    display: block;
    text-align: center;
  }
  .call-to-action-input-wrap {
    /* width: 300px; */
    margin: 20px auto;
  }
}
