.header-navbar ul li.active {
  color: red;
}

.header-inner-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: 0.3s;
}

.header-navbar ul > li {
  list-style: none;
  display: inline-block;
}

.header-navbar ul > li > a {
  text-decoration: none;
  padding: 10px 15px;
  padding-right: 25px;
  color: #000;
  height: 80px;
  display: flex;
  align-items: center;
  background: none;
  font-size: 14px;
  /* font-family: "Raleway"; */
  cursor: pointer;
}
.header-navbar ul > li > a.active {
  font-weight: 600;
}
.menuToggle {
  position: absolute;
  right: 0;
}

.navbar_mobile_menu_bar {
  width: 100%;
  height: 100%;
}

.navbar_mobile_menu_bar span:nth-child(3) {
  margin-bottom: 0;
}

.navbar_mobile_menu_bar span {
  display: block;
  width: 25px;
  height: 2px;
  background: #000;
  margin-bottom: 6px;
  transition: 0.5s;
}

.navbar_mobile_close.remove {
  position: absolute;
  right: -15px;
  z-index: 999999;
}

.navbar_mobile_close.remove span {
  display: block;
  width: 26px;
  height: 2px;
  background: #000;
  transition: 0.3s;
}

.navbar_mobile_close.remove span:nth-child(1) {
  transform: rotate(45deg);
  position: relative;
  top: 1px;
  right: 12px;
}

.navbar_mobile_close.remove span:nth-child(2) {
  transform: rotate(-45deg);
  position: relative;
  top: 0px;
  right: 12px;
}

.navbar_mobile_close.remove span:nth-child(3) {
  display: none;
}

.menuToggle {
  display: none;
}

.navbar__link {
  border-bottom: 3px solid transparent;
}

.navbar__link--active {
  color: #0180f5 !important;
  border-bottom: none;
  transition: border-bottom 0.5s ease-in-out;
  font-weight: 600;
  position: relative;
}
/* a.home-page-navbar-link-btn {
  background: #0180f5 !important;
  height: 40px !important;
  color: #fff !important;
  font-weight: 700;
  border-radius: 10px;
} */
.header-navbar ul {
  margin-bottom: 0;
  padding-left: 0;
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 992px) {
  .header-logo img {
    width: 120px;
  }

  .menuToggle {
    display: block;
  }

  nav.navbar_mobile_menu.show {
    transition: 0.3s;
    position: absolute;
    z-index: 999;
    width: 122%;
    height: 90vh;
    right: -12%;
    top: 45px;
    background: #fff;
    transform-origin: right;
    transform: scale(1);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }

  nav.navbar_mobile_menu.hide {
    position: absolute;
    right: 0;
    width: 0;
    opacity: 0;
    transform: scale(0);
    transition: 0.3s;
    top: 75px;
    height: 100%;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
  }

  nav.navbar_mobile_menu.show ul {
    /* width: 90%; */
    margin-top: 30px;
  }

  nav.navbar_mobile_menu.show ul > li {
    display: block;
    /* padding: 10px; */
    /* padding-bottom: 15px; */
    border-bottom: 1px solid #ddd;
  }

  .nav-item.dropdown > a {
    padding: 0;
  }
}

.nav-item.show.dropdown > a {
  color: red !important;
  font-weight: 600;
}

.header-navbar ul > li > a > span {
  position: absolute;
  text-align: center;
  opacity: 0;
}

.nav-link {
  color: #000 !important;
}

.header-navbar .dropdown-menu.show {
  width: 100vw;
  transform: translate(-62%, 63px);
}

.nav-item.show.dropdown .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1vw;
  padding-top: 50px;
  padding-bottom: 50px;
}

.dropdown-menu.show > .container a {
  display: inline-block;
  width: 20%;
  color: #9c9c9c;
  text-decoration: none;
}
.dropdown-menu.show > .container a:hover {
  color: red;
}
.header-navbar .dropdown-menu.show {
  width: 204vw;
  transform: translate(-58%, 53px) !important;
  inset: 7px auto auto 0px !important;
  border: none;
}

.slider-wrapper.hero-inner-area.home-page-main {
  position: relative;
  z-index: 99;
}

@media only screen and (max-width: 1600px) {
  .header-navbar .dropdown-menu.show {
    width: 140vw;
    transform: translate(-58.5%, 50px) !important;
    inset: 10px auto auto 0px !important;
    border: none;
  }

  .nav-item.show.dropdown .container {
    padding-top: 15px;
  }

  .dropdown-menu.show > .container a {
    display: inline-block;
    width: 18%;
  }

  nav.navbar_mobile_menu.show {
    width: 150%;
    right: -25%;
    top: 45px;
  }
}

@media only screen and (max-width: 1500px) {
  .header-navbar .dropdown-menu.show {
    width: 140vw;
    transform: translate(-65.5%, 50px) !important;
    inset: 10px auto auto 0px !important;
    border: none;
  }

  .nav-item.show.dropdown .container {
    padding-top: 15px;
  }

  .dropdown-menu.show > .container a {
    display: inline-block;
    width: 18%;
  }

  nav.navbar_mobile_menu.show {
    width: 150%;
    right: -25%;
    top: 45px;
  }
}

@media only screen and (max-width: 1400px) {
  .header-navbar .dropdown-menu.show {
    width: 140vw;
    transform: translate(-55%, 50px) !important;
    inset: 10px auto auto 0px !important;
    border: none;
  }

  .nav-item.show.dropdown .container {
    padding-top: 15px;
  }

  .dropdown-menu.show > .container a {
    display: inline-block;
    width: 20%;
  }

  nav.navbar_mobile_menu.show {
    width: 150%;
    right: -25%;
    top: 45px;
  }
}

@media only screen and (max-width: 1300px) {
  .header-navbar .dropdown-menu.show {
    width: 140vw;
    transform: translate(-63%, 50px) !important;
    inset: 10px auto auto 0px !important;
    border: none;
  }

  .nav-item.show.dropdown .container {
    padding-top: 15px;
  }

  .dropdown-menu.show > .container a {
    display: inline-block;
    width: 20%;
  }

  nav.navbar_mobile_menu.show {
    width: 150%;
    right: -25%;
    top: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .header-navbar .dropdown-menu.show {
    width: 140vw;
    transform: translate(-58.3%, 50px) !important;
    inset: 10px auto auto 0px !important;
    border: none;
  }

  .nav-item.show.dropdown .container {
    padding-top: 15px;
  }

  .dropdown-menu.show > .container a {
    display: inline-block;
    width: 20%;
  }

  nav.navbar_mobile_menu.show {
    width: 150%;
    right: -25%;
    top: 45px;
  }
}

@media only screen and (max-width: 1100px) {
  .header-navbar .dropdown-menu.show {
    width: 140vw;
    transform: translate(-60%, 50px) !important;
    inset: 10px auto auto 0px !important;
    border: none;
  }

  .nav-item.show.dropdown .container {
    padding-top: 15px;
  }

  .dropdown-menu.show > .container a {
    display: inline-block;
    width: 20%;
  }

  nav.navbar_mobile_menu.show {
    width: 150%;
    right: -25%;
    top: 45px;
  }
}

@media only screen and (max-width: 992px) {
  .header-navbar .dropdown-menu.show {
    width: 90vw;
    transform: translate(-4%, 20px) !important;
    inset: 10px auto auto 0px !important;
    border: none;
  }

  .nav-item.show.dropdown .container {
    padding-top: 15px;
  }

  .dropdown-menu.show > .container a {
    display: inline-block;
    width: 31%;
  }

  nav.navbar_mobile_menu.show {
    width: 150%;
    right: -25%;
    top: 45px;
  }

  a.navbar__link.navbar__link--active:after {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  nav.navbar_mobile_menu.show {
    width: 150%;
    right: -25%;
    top: 45px;
  }

  .header-navbar ul > li > a {
    height: 60px;
    padding: 0px 15px;
  }

  .dropdown-menu.show > .container a {
    width: 32%;
    color: gray;
    text-decoration: none;
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {
  .header-navbar .dropdown-menu.show {
    width: 100vw;
    transform: translate(-4%, 20px) !important;
    inset: 10px auto auto 0px !important;
    border: none;
  }

  nav.navbar_mobile_menu.show {
    width: 110%;
    right: -5%;
    top: 45px;
  }

  .dropdown-menu.show > .container {
    gap: 2vw !important;
  }

  .dropdown-menu.show > .container a {
    width: 42%;
  }
}

header.header-main-area:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 10px;
  background: #ffffff2e;
  bottom: -12px;
}

.slider-wrapper.hero-inner-area.home-page-main:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 15px;
  background: linear-gradient(0deg, #ffffff40, transparent);
  left: 0;
  bottom: 0px;
}
